import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { notification } from 'ant-design-vue'

const AXIOS = axios.create({
    baseURL: `/logapi`
    /* timeout: 60000 */
})

AXIOS.interceptors.response.use((res) => {
    return res
    }, (error) => {
        // console.log('Interceptor detected Error: ', error)
        if (error.response.status === 401) {
            console.log('401 Error detected')
            document.cookie = "authenticated=false"
            notification.error({
                message: 'Sitzung abgelaufen',
                description:
                  'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
                duration: 6
            })
            store.commit("setAuthentification", false)
            router.push({ path: '/'})
        }
    return Promise.reject(error)
});

export default {
    logout () {
        return AXIOS.get('/logout')
    },
    login (params) {
        return AXIOS.post('/login', params, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    },
    generateCode (params) {
        return AXIOS.post('/otp/generate',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    verifyOtp (params){
        return AXIOS.post('/otp/verify',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    validateOtp (params){
        return AXIOS.post('/otp/validate',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    sendKey (params) {
        return AXIOS.post('/sendCode',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    checkSaasManager (accountuuid) {
        return AXIOS.get('/checkSaasManager/' + accountuuid)
    },
    getPartnerWithPlan (uuid) {
        return AXIOS.get('/getPartnerSubscription/'+uuid)
    },
    setCookies (param){
        return AXIOS.post('/setCookie',param,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    getReferer () {
        return AXIOS.get('/getReferer')
    },
    getAgbStatus (params) {
        return AXIOS.post('/getAgbStatus',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    createNews (params) {
        return AXIOS.post('/createNews',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    editNews (params) {
        return AXIOS.post('/editNews',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    deleteNews (params) {
        return AXIOS.post('/deleteNews',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    getNews () {
        return AXIOS.get('/getNews')
    },
    getAllNews () {
        return AXIOS.get('/getAllNews')
    },
    getMaintenances () {
        return AXIOS.get('/getMaintenances')
    },
    //check Session an Refresh apis
    checkSession() {
        return AXIOS.get('/checkSession')
    },
    checkValidSession() {
        return AXIOS.get('/checkValidSession')
    },
    refreshSession() {
        return AXIOS.get('/refreshSession')
    },
}
